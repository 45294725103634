import React from "react";
import { useNavigate } from "react-router-dom";
import HelpTicketFull, { HelpTicketUpdateVO } from "../models/helpticket.model";
import { useUpdateHelpTicketMutation } from "../utilities/apiApi";
import { getDuration, getTicketStatusColor, getTicketStatusIcon } from "../utilities/helpTicketUtils";
import { notify } from "./Notification/Notification";
import ViewTicket from "./ViewTicket";

export default function TicketsOverview({ helpTickets }: { helpTickets: Array<HelpTicketFull> }) {
	const [isViewingHelpTicket, setIsViewingHelpTicket] = React.useState(false);
	const [helpTicketBeingViewedGUID, setHelpTicketBeingViewedGUID] = React.useState<string>();
	const [updateHelpTicket, updateResult] = useUpdateHelpTicketMutation();
	const navigate = useNavigate();

	const updateTicket = async (preamble: string, helpTicketUpdateVO: HelpTicketUpdateVO, shouldSkipNotify: boolean = false) => {
		if (!updateResult.isLoading) {
			if (helpTicketUpdateVO.note) {
				try {
					await updateHelpTicket({ update: helpTicketUpdateVO }).unwrap();
					if (!shouldSkipNotify) {
						if (helpTicketUpdateVO.note !== undefined) {
							notify(preamble, "Note was added", false);
						} else {
							notify(preamble, "", false);
						}
					}
				} catch (error) {
					notify("Error", "Failed updating the help ticket", true);
					// console.error("Failed to update the help ticket: " + error);
				}
			}
		}
	};

	return (
		<div>
			<div className={"mb-8"}>
				<div className={"flex flex-row flex-wrap justify-center"}>
					<div className={"flex  w-full  sm:w-[237px] md:w-[300px]   m-4 bg-primary/[6%] h-52 rounded-lg"}>
						<button
							className={"w-full h-full hover:bg-black/[2%] focus:bg-black/[2%] focus:outline-0 active:bg-black/[4%] rounded-lg"}
							onClick={() => {
								navigate("/helpdesk/newTicket");
							}}
						>
							<div className={"flex flex-col place-items-center"}>
								<img src={"/ticket-add.svg"} alt={"Button to add a help ticket"} className={"w-20 h-20 mb-2"} />
								<div className="text-primary font-rubik-medium text-base md:text-xl font-medium tracking-normal text-center">
									Create New Ticket
								</div>
							</div>
						</button>
					</div>
					{helpTickets?.map((helpTicket) => (
						<div className="flex w-full   sm:w-[237px] md:w-[300px]  m-4" key={helpTicket.helpTicketGUID}>
							<div className={"w-full bg-white h-52 rounded-lg shadow-lg"}>
								<button
									className={"w-full h-full focus:outline-0 hover:bg-black/[4%] focus:bg-black/[5%] active:bg-black/[10%] rounded-lg"}
									onClick={() => {
										setIsViewingHelpTicket(true);
										setHelpTicketBeingViewedGUID(helpTicket.helpTicketGUID);
									}}
								>
									<div className={"flex flex-col w-full h-full break-all"}>
										<div
											className={
												"flex justify-start items-center h-8 w-full mb-4 rounded-t-lg " +
												(helpTicket.status && getTicketStatusColor(helpTicket.status.name))
											}
										>
											<img
												src={helpTicket.status && getTicketStatusIcon(helpTicket.status.name)}
												alt={"Ticket status icon"}
												className={"ml-4 mr-2 w-4 h-4"}
											/>
											<div className={"text-open-dark font-rubik text-sm  font-semibold "}>
												{helpTicket.status && helpTicket.status.name}
											</div>
										</div>
										<div className="text-altblack font-rubik-medium text-base font-medium text-start mb-4 mx-4">
											{helpTicket.summary.length > 60 ? helpTicket.summary.substring(0, 60) : helpTicket.summary}
										</div>
										<div className="text-altblack font-roboto text-sm font-light text-start mb-4 mx-4 flex-auto">
											Opened: {helpTicket.statusCreatedDuration ? helpTicket.statusCreatedDuration : getDuration(helpTicket.dateCreated)}
										</div>
										<div className={"flex justify-start items-center mx-4 mb-2"}>
											<img src={"/time-clock-circle.svg"} alt={"Ticket last updated clock icon"} className={"w-4 h-4 mr-2"} />
											<div className={"text-altblack font-roboto text-sm text-left"}>
												{helpTicket.statusUpdatedDuration ? helpTicket.statusUpdatedDuration : getDuration(helpTicket.dateUpdated)}
											</div>
										</div>
									</div>
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
			{isViewingHelpTicket && helpTicketBeingViewedGUID && (
				<ViewTicket
					helpTicketGUID={helpTicketBeingViewedGUID}
					isViewingHelpTicket={isViewingHelpTicket}
					setIsViewingHelpTicket={setIsViewingHelpTicket}
					canEditHelpTicket={false}
					updateTicket={updateTicket}
				/>
			)}
		</div>
	);
}
