import store from "../store";
import { authApi } from "./authApi";
import { getPortalClientScriptUrl, getPortalParentWindowMessagingUrl } from "./configService";

const PORTAL_SCRIPT_ELEMENT_ID = "portaljsElem";

const portalInit = async (theSearch: any) => {
	if (theSearch === "") {
		return;
	}
	const portalScript: HTMLScriptElement = document.createElement("script");
	portalScript.type = "module";
	portalScript.async = false;
	portalScript.id = PORTAL_SCRIPT_ELEMENT_ID;
	portalScript.text = `
      import Portal from '${getPortalClientScriptUrl()}';
      window.portal = Portal;
  `;
	document.body.appendChild(portalScript);
	try {
		await waitForWindowPortalToExist();
		const portal = (window as any).portal;
		const queryParams = new URLSearchParams(theSearch);
		const portalId = queryParams.get("portalId");
		const callback = (m: any) => {
			console.log("Help Desk recieved default callback message: ", m);
		};
		// let portalUrl = queryParams.get("nue-parent-url"); Please keep this here for reference.
		portal.initialize("Help Desk", getPortalParentWindowMessagingUrl(), portalId);
		registerKeepAliveHandler(portal, callback);
		registerUserStateChangeHandler(portal);
		registerActivateHandler(callback);
		registerPassivateHandler(callback);
		registerTenantChangeHandler(callback);
		registerAppActionHandler(callback);
		registerGetPageUrlHandler(callback);
		registerNotificationClickHandler(callback);
		registerTenantChangeHandler(callback);
		registerNavigationScopeChangeCallbackHandler(callback);
		portal.setReady(true);
	} catch (e) {
		console.error("Failed to connect to portal: ", e);
	}
};

const registerGetPageUrlHandler = (callback: any) => {
	(window as any).portal.registerGetPageUrlHandler(callback);
};

const registerNotificationClickHandler = (callback: any) => {
	(window as any).portal.registerNotificationClickHandler(callback);
};

const registerNavigationScopeChangeCallbackHandler = (callback: any) => {
	(window as any).portal.registerNavigationScopeChangeCallbackHandler(callback);
};

const registerAppActionHandler = (callback: any) => {
	(window as any).portal.registerAppActionHandler();
};

const registerKeepAliveHandler = (portal: any, callback: any) => {
	portal.registerKeepAliveHandler(callback);
};

export const registerActivateHandler = (callback: any) => {
	(window as any).portal.registerActivateHandler(callback);
};

export const registerPassivateHandler = (callback: any) => {
	(window as any).portal.registerPassivateHandler(callback);
};

export const registerUserStateChangeHandler = (portal: any) => {
	portal.registerUserStateChangeHandler(async (msg: any) => {
		console.log("Recieved UserStateChanged message: ", msg.message.event);
		if (msg.message.event === "LOGOUT") {
			portal.setReady(true, true);
			await store.dispatch(authApi.endpoints.logout.initiate());
			if (msg.message?.authCode) {
			}
		} else if (msg.message.event === "LOGIN") {
			portal.setReady(true, true);
			if (msg.message?.authCode) {
			}
		}
	});
};

export const registerTenantChangeHandler = (callback: any) => {
	(window as any).portal.registerTenantChangeHandler(callback);
};

export const setReady = (ready: boolean) => {
	(window as any).portal.setReady(ready);
};

export const waitForWindowPortalToExist = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		const intervalMs = 50;
		const maxDurationMs = 5000;
		const maxIterations = maxDurationMs / intervalMs;
		let itrCnt = 0;
		const theInterval = setInterval(() => {
			if ((window as any).portal !== undefined) {
				clearInterval(theInterval);
				resolve();
			}
			if (++itrCnt >= maxIterations) {
				clearInterval(theInterval);
				reject("failed");
			}
		}, intervalMs);
	});
};

export { portalInit };
