import _ from "lodash";
import { blankDevice, Device } from "./device.model";
import { FieldType } from "./fields.model";
import { blankPatron, Patron } from "./Patron.model";

export type SortSpec = {
	order: "asc" | "desc" | "ASC" | "DESC";
	orderBy: string;
};

export interface ServicingTeam {
	name: string;
	id: number;
}
export interface ImageData {
	imageFilename: string;
	type: string;
	data: string;
}
export interface CustomFieldValue {
	helpTicketFieldValueID?: number;
	helpTicketFieldID?: number;
	fieldName: string;
	textValue: string;
	fieldType: FieldType;
	editable: boolean;
	required: boolean;
	dropDownOptions?: string[];
}
export const blankCustomFieldValue = {
	fieldName: "",
	textValue: "",
	fieldType: 0,
	editable: false,
	required: false,
};

export interface DateFormat {
	format: string;
}
export interface AttachedFile {
	name: string;
	documentLocation?: string;
	size: number;
	type?: string;
	lastModified?: string;
	thumbnailUrl?: string;
}

export interface SavedFile {
	id?: string;
	displayName: string;
	url?: string;
	thumbnailUrl?: string;
	name: string;
	size: number;
	type: string;
	lastModified: string;
}

export interface TicketHistory {
	helpTicketGUID?: string;
	dateCreated?: string;
	type: number; // if type === 1 it's a note
	description?: string;
	originatingPatron?: Patron;
	assignedPatronName?: string;
	requestedPatronName?: string;
	assignedServicingTeamName?: string;
	status?: number;
	priority?: number;
	email?: string;
	phone?: string;
	resolutionName?: string;
	duplicateTicketNumber?: string;
	performedBy?: string;
	summary?: string;
	details?: string;
	submittedForRepair?: boolean;
	expectedCompletionDate?: string;
	statusCreatedDuration?: string;
}

export interface IssueType {
	helpTicketIssueTypeID?: number;
	name: string;
	parentId?: string;
	active: boolean;
	children?: number;
}

export interface Category extends IssueType {
	issueTypes: Array<IssueType>;
}
export interface DestinyUser {
	userID: number;
	userGUID: string;
	userLoginID: string;
	firstName: string;
	lastName: string;
	middleName: string;
	isAdmin: boolean;
}

export interface Resolution {
	helpTicketResolutionID?: number;
	name: string;
	nameSortKey: string;
	description: string;
	active: boolean;
}

export const blankResolution: Resolution = {
	helpTicketResolutionID: undefined,
	name: "",
	nameSortKey: "",
	description: "",
	active: true,
};

export interface NumberNamePair {
	id: number;
	name: string;
}

export interface AssigneeMenuItem {
	type: "team" | "individual";
	name: string;
	serviceTeamID?: number;
	id?: string;
	isDistrictUser?: boolean;
	disabled?: boolean;
}
export interface HelpTicketRow {
	assignedDistrictGUID?: string;
	assignedDistrictUser?: string;
	assignedPatronGUID?: string;
	assignedPatronName?: string;
	dateCreated?: string;
	dateUpdated?: string;
	helpTicketAssignees: Array<AssigneeMenuItem>;
	helpTicketGUID: string;
	item: Device;
	priority?: number;
	servicingTeamID?: number;
	servicingTeamName?: string;
	statusCreatedDuration?: string;
	status?: string;
	statusUpdatedDuration?: string;
	summary: string;
	ticketNumber?: number;
}

// tracks HelpTicketVO in backend with some extras needed for upsert.
export default interface HelpTicketFull {
	assignedDistrictUser?: DestinyUser; // UserVO
	assignedPatron?: Patron;
	assignedServicingTeam?: ServicingTeam;
	assignedTo?: string;
	attachedFiles?: Array<AttachedFile>; // attachedFiles from Server
	addFiles?: Array<File>;
	category?: IssueType;
	customFields?: Array<CustomFieldValue>;
	dateCompleted?: string;
	dateCreated?: string;
	dateUpdated?: string;
	statusCreatedDuration?: string;
	statusUpdatedDuration?: string;
	details?: string;
	duplicateTicketID?: number;
	email?: string;
	emailEnabled?: boolean;
	expectedCompletionDate?: string;
	firstTimeResolution?: boolean;
	helpTicketGUID?: string;
	helpTicketID?: string;
	helpTicketAssignees?: Array<HelpTicketAssigneeVO>;
	issueType?: IssueType;
	item: Device;
	note?: string;
	offendingPatron?: Patron;
	phone?: string;
	priority: NumberNamePair | undefined;
	requestedForPatron?: Patron; //changed
	resolution?: Resolution;
	resolutionDescription?: string;
	status: NumberNamePair | undefined;
	submittedForRepair?: boolean;
	submittingPatron?: Patron;
	summary: string;
	ticketHistory?: Array<TicketHistory>;
	ticketNumber?: number;
}
export interface HelpTicketsVO {
	totalCount?: number;
	helpTickets: Array<HelpTicketFull>;
}
// leaving this here for reference this is the fields in helpTicketFull that are
// filled when using the new minimalized return from loggedinuser call.

// export interface HelpTicketMinimalVO {
// 	assignedDistrictUser?: DestinyUser; // UserVO
// 	assignedPatron?: Patron;
// 	assignedServicingTeam?: ServicingTeam;
// 	category?: IssueType;
// 	dateCreated?: string;
// 	dateUpdated?: string;
// 	statusCreatedDuration?: string;
// 	statusUpdatedDuration?: string;
// 	helpTicketGUID?: string;
// 	helpTicketID?: string;
// 	issueType?: IssueType;
// 	item: Device;
// 	priority: NumberNamePair | undefined;
// 	status: NumberNamePair | undefined;
// 	summary: string;
// 	ticketNumber: number;
// }

export interface CreateHelpTicket {
	assignedDistrictUserGUID?: string; // missing
	assignedPatronGUID?: string;
	assignedServicingTeamID?: number;
	issueCategory?: number; // issueCategoryID
	details?: string;
	email?: string;
	expectedCompletion?: string;
	issueType?: number; // issueTypeID
	itemGUID?: string;
	offendingPatronGUID?: string;
	phone?: string; // not set-able
	priority?: number;
	requestedPatronGUID?: string; // rename from requestForPatron
	resolutionID?: number;
	resolutionDescription?: string; // missing
	status?: number;
	submittedForRepair?: boolean; // will always be false
	summary?: string;
	customFieldValues?: Array<CustomFieldValue>;
}

export const blankHelpTicketFull = {
	assignedPatron: undefined,
	assignedServicingTeam: undefined,
	assignedDistrictUser: undefined,
	category: undefined,
	dateCompleted: undefined,
	dateCreated: undefined,
	dateUpdated: undefined,
	details: "",
	statusUpdatedDuration: undefined,
	duplicateTicketID: undefined,
	email: undefined,
	emailEnabled: false,
	expectedCompletionDate: undefined,
	firstTimeResolution: false,
	getAssignedDistrictUser: undefined,
	helpTicketID: undefined,
	helpTicketGUID: "",
	helpTicketAssignees: [],
	issueType: undefined,
	item: _.cloneDeep(blankDevice),
	offendingPatron: undefined,
	phone: undefined,
	priority: undefined,
	requestedForPatron: _.cloneDeep(blankPatron),
	resolution: undefined,
	resolutionDescription: undefined,
	status: undefined,
	submittedForRepair: false,
	submittingPatron: undefined,
	summary: "",
	ticketNumber: undefined,
	customFields: [],
	attachedFiles: [],
	ticketHistory: [],
};

// add fields to this interface that can change when editing AND have
// a HelpTicketHistoryType
export interface HelpTicketUpdateVO {
	addFiles?: Array<File>;
	assignedDistrictUserGUID?: string;
	assignedPatronGUID?: string;
	assignedServicingTeamID?: number;
	deleteFiles?: Array<AttachedFile>;
	details?: string;
	email?: string;
	helpTicketGUID?: string; // may be ? but it is required.
	issueCategory?: number;
	issueType?: number;
	note?: string;
	phone?: string;
	offendingPatronGUID?: string;
	priority?: number;
	resolutionDescription?: string;
	resolutionID?: number;
	status?: number;
	summary?: string;
}

export interface HelpTicketAssignedServicingTeamVO {
	servicingTeamID: number;
	servicingTeamName: string;
	teamMemberAssignees: HelpTicketAssigneeVO[];
}
export interface HelpTicketAssigneeVO {
	userLoginID: string;
	userGUID: string;
	lastFirstMiddleName: string;
	isDistrictUser: boolean;
	servicingTeamName: string;
	servicingTeamID: number;
}
// not following interface does not follow like named Class in backend/destiny-api
export interface HelpTicketAssigneesVO {
	assignedServicingTeams: HelpTicketAssignedServicingTeamVO[];
	individualAssignees: HelpTicketAssigneeVO[];
}
export interface FetchImageResponse {
	data: Blob | ArrayBuffer;
}
export interface DownloadResponse {
	data: Blob | MediaSource;
}
export interface DownloadAttachedFile {
	helpTicketGUID: string;
	file: AttachedFile;
}
