import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import AdminSettings from "../../components/AdminSettings/AdminSettings";
import CategoryManagement from "../../components/CategoryManagement/CategoryManagement";
import EmailNotificationsConfiguration from "../../components/EmailNotificationsConfiguration/EmailNotificationsConfiguration";
import FieldsConfiguration from "../../components/FieldsConfiguration/FieldsConfiguration";
import FormsConfiguration from "../../components/FormsConfiguration/FormsConfiguration";
import Header from "../../components/Header/Header";
import NewTicketFull from "../../components/NewTicketFull/NewTicketFull";
import ReportsConfiguration from "../../components/ReportsConfiguration/ReportsConfiguration";
import ResolutionCodesConfiguration from "../../components/ResolutionCodesConfiguration/ResolutionCodesConfiguration";
import Sidebar from "../../components/Sidebar/Sidebar";
import TicketsListOverview from "../../components/TicketsListOverview";
import { useAppSelector } from "../../hook";
import { FilterForm } from "../../models/filterForm.model";
import { SortSpec } from "../../models/helpticket.model";
import { selectHelpTicketFilterForm } from "../../slices/FiltersSlice";

export default function AdminPage() {
	let developmentButtons = false;
	const [isFiltering, setIsFiltering] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [sort, setSort]: [Array<SortSpec>, (value: Array<SortSpec>) => void] = useState([{ orderBy: "dateUpdated", order: "desc" }] as Array<SortSpec>);
	const [pageMode, setPageMode] = useState("Home");
	const headerPageModes = ["Home"];
	const [showFormsHeader, setShowFormsHeader] = useState(true);
	const ticketFilter: FilterForm = useAppSelector(selectHelpTicketFilterForm);

	return (
		<div className={"min-w-screen min-h-screen flex flex-row"}>
			{pageMode !== "Add Ticket" && <Sidebar pageMode={pageMode} setPageMode={setPageMode} />}
			<div className={"w-full " + (pageMode !== "Add Ticket" ? "pl-[4rem]" : "")}>
				<div className={"" + (pageMode !== "Add Ticket" ? "px-4 xl:px-12 2xl:px-24 3xl:px-36" : "")}>
					<header>
						{headerPageModes.includes(pageMode) ? (
							<Header
								title={"Tickets"}
								onFilter={(value: boolean) => {
									setIsFiltering(value);
								}}
							/>
						) : pageMode === "Settings" ? (
							<div className="text-altblack mt-6 mb-6 flex md:flex-row flex-col items-center font-rubik font-normal text-2xl leading-normal grow">
								Admin
							</div>
						) : (
							<div className={"mb-4"} />
						)}
					</header>
					<main>
						<>
							{/* {pageMode === "Home" && totalCount > 0 && <TicketsListOverview key="list_admin" />}
							{pageMode === "Home" && totalCount == 0 && isFiltering && <TicketsNoResults />}
							{pageMode === "Home" && totalCount == 0 && !isFiltering && <TicketsColdStart />} */}
							{pageMode === "Home" && <TicketsListOverview isFiltering={isFiltering} />}
							{pageMode === "Add Ticket" && <NewTicketFull setPageMode={setPageMode} />}
							{pageMode === "Settings" && (
								<AdminSettings
									gotoSettings={(value: string) => {
										setPageMode("Settings:" + value);
									}}
								/>
							)}
							{pageMode === "Settings:Issue Categories" && (
								<div className="text-altblack text-m py-2">
									<IconButton
										className="text-primary text-2xl"
										onClick={() => {
											setPageMode("Settings");
										}}
										disableRipple={true}
									>
										<div className={"flex flex-row items-center mb-4"}>
											<ArrowBack />
											<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
										</div>
									</IconButton>

									<CategoryManagement />
								</div>
							)}
							{pageMode === "Settings:Fields" && (
								<div className="text-altblack text-m py-2">
									<IconButton
										className="text-primary text-2xl"
										onClick={() => {
											setPageMode("Settings");
										}}
										disableRipple={true}
									>
										<div className={"flex flex-row items-center mb-4"}>
											<ArrowBack />
											<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
										</div>
									</IconButton>
									<FieldsConfiguration
										onBack={(value) => {
											setPageMode(value);
										}}
									/>
								</div>
							)}
							{pageMode === "Settings:Forms" && (
								<div className="text-altblack text-m py-2">
									{showFormsHeader && (
										<IconButton
											className="text-primary text-2xl"
											onClick={() => {
												setPageMode("Settings");
											}}
											disableRipple={true}
										>
											<div className={"flex flex-row items-center mb-4"}>
												<ArrowBack />
												<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
											</div>
										</IconButton>
									)}
									<FormsConfiguration showFormsHeader={showFormsHeader} setShowFormsHeader={setShowFormsHeader} />
								</div>
							)}
							{pageMode === "Settings:Resolution Codes" && (
								<div className="text-altblack text-m py-2">
									<IconButton
										className="text-primary text-2xl"
										onClick={() => {
											setPageMode("Settings");
										}}
										disableRipple={true}
									>
										<div className={"flex flex-row items-center mb-4"}>
											<ArrowBack />
											<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
										</div>
									</IconButton>

									<ResolutionCodesConfiguration />
								</div>
							)}
							{pageMode === "Settings:Email Notifications" && (
								<div className={"text-altblack text-m py-2"}>
									<IconButton
										className="text-primary text-2xl"
										onClick={() => {
											setPageMode("Settings");
										}}
										disableRipple={true}
									>
										<div className={"flex flex-row items-center mb-4"}>
											<ArrowBack />
											<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
										</div>
									</IconButton>

									<EmailNotificationsConfiguration />
								</div>
							)}
							{pageMode === "Settings:Reports" && (
								<div className="text-altblack text-m py-2">
									<ReportsConfiguration setPageMode={setPageMode} />
								</div>
							)}
							{[
								// "Activity Log",
								// "Agents",
								// "Email",
								// "Rule Automations",
								// "Satisfaction Survey",
								// "Service Teams",
								// "Auditing",
								// "Automatic Processing",
								// "Help Ticket Setup",
								// "Workforce Management",
								// "Other",
							].map((value: string) => {
								return (
									pageMode === `Settings:${value}` && (
										<div className="text-altblack  text-m">
											<IconButton
												className="text-primary text-2xl"
												onClick={() => {
													setPageMode("Settings");
												}}
											>
												<div className={"flex flex-row items-center mb-4"}>
													<ArrowBack />
													<span className="text-altblack">{" " + pageMode.substring(pageMode.indexOf(":") + 1)}</span>
												</div>
											</IconButton>
										</div>
									)
								);
							})}
						</>
					</main>
				</div>
			</div>
		</div>
	);
}
