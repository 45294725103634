import {
	Box,
	Checkbox,
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
} from "@mui/material";
import _ from "lodash";
import React, { SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hook";
import { Sortable, SortAscending, SortDescending } from "../icons/TableHeaderIcons";
import { FilterForm } from "../models/filterForm.model";
import HelpTicketFull, { AssigneeMenuItem, HelpTicketRow, HelpTicketsVO, HelpTicketUpdateVO, Resolution, SortSpec } from "../models/helpticket.model";
import { selectHelpTicketFilterForm } from "../slices/FiltersSlice";
import { selectHelpTickets } from "../slices/HelpTicketSlice";
import { useRequestedMyHelpTicketsFullQuery, useResolutionsQuery, useUpdateHelpTicketMutation } from "../utilities/apiApi";
import { getFeatureFlags } from "../utilities/appContext";
import {
	convertAssigneesToMenu,
	getAssigneeName,
	getDuration,
	getLastFirstMiddleNameDestinyUser,
	getLastFirstMiddleNamePatron,
	getPriority,
	getTicketStatus,
	getTicketStatusIconBlack,
} from "../utilities/helpTicketUtils";
import CommonProgress from "./CommonProgress/CommonProgress";
import CustomButton from "./CustomButton/CustomButton";
import { notify, notifyDialog, notifyList } from "./Notification/Notification";
import ResourceImage from "./ResourceImage/ResourceImage";
import StatusSelect from "./StatusSelect/StatusSelect";
import TicketsColdStart from "./TicketsColdStart";
import TicketsNoResults from "./TicketsNoResults";
import ViewTicket from "./ViewTicket";

const headCells = [
	{
		id: "image",
		numeric: false,
		canSort: false,
		disablePadding: true,
		label: "",
	},
	{
		id: "summary",
		numeric: false,
		canSort: false,
		disablePadding: true,
		label: "Ticket",
	},
	{
		id: "priority",
		numeric: true,
		canSort: true,
		disablePadding: true,
		label: "Priority",
	},
	{
		id: "assigned",
		numeric: true,
		canSort: false,
		disablePadding: true,
		label: "Assigned",
	},
	{
		id: "dateCreated",
		numeric: true,
		canSort: true,
		disablePadding: true,
		label: "Opened",
	},
	{
		id: "dateUpdated",
		numeric: true,
		canSort: true,
		disablePadding: true,
		label: "Updated",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: true,
		canSort: true,
		label: "Status",
	},
];

interface EnhancedTableHeadProps {
	helpTickets: HelpTicketFull[];
	selected: string[];
	onRequestSort: (event: any, property: string) => void;
	onSelectAllClick: (event: any) => void;
	sort: SortSpec[];
	rowCount: number;
}
const defaultSortSpec: { [key: string]: SortSpec | null } = {
	status: null,
	dateUpdated: { order: "desc", orderBy: "dataUpdated" },
	dateCreated: null,
	assigned: null,
	priority: null,
};
function EnhancedTableHead(props: EnhancedTableHeadProps) {
	const { onSelectAllClick, sort, helpTickets, selected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: any) => (event: any) => {
		onRequestSort(event, property);
	};
	let foundInSelected = 0;
	let notFoundInSelected = 0;
	if (helpTickets && selected) {
		for (let i = 0; i < helpTickets.length; i++) {
			let found = false;
			for (let j = 0; j < selected.length; j++) {
				if (selected[j] === helpTickets[i].helpTicketGUID) {
					found = true;
					break;
				}
			}
			if (found) {
				foundInSelected++;
			} else {
				notFoundInSelected++;
			}
		}
	}
	let checked = false;
	let eligibleTickets = _.filter(helpTickets, (item) => item.status?.id != 2);
	let eligibleCount = eligibleTickets ? eligibleTickets.length : 0;
	let disabled = eligibleCount === 0;
	checked = foundInSelected > 0 && eligibleCount > 0 && eligibleCount === foundInSelected;
	let indeterminate = !checked && foundInSelected > 0;
	let sortOrder: { [key: string]: SortSpec } = {};

	if (sort && sort.length > 0) {
		for (let i = 0; i < sort.length; i++) {
			sortOrder[sort[i].orderBy] = _.cloneDeep(sort[i]);
		}
	}
	function isActive(id: string) {
		return _.find(sort, (item) => {
			item.orderBy == id;
		})
			? false
			: true;
	}
	function getSortDirection(id: string): any {
		if (sortOrder[id] != null) {
			return sortOrder[id].order.toLowerCase();
		}
		return false;
	}
	function getTooltip(id: string) {
		if (sortOrder[id]) {
			return sortOrder[id].order === "desc" ? "sorted descending" : "sorted ascending";
		}
		return "This column is sortable.";
	}
	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<div className={"flex flex-row items-center"}>
						<Checkbox
							key={"checkbox_header"}
							color={"primary"}
							// indeterminate={numSelected > 0 && numSelected < rowCount}
							// checked={rowCount > 0 && numSelected === rowCount}
							indeterminate={indeterminate}
							disabled={disabled}
							checked={checked}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "Select all help tickets",
							}}
							disableRipple={true}
							sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
						/>
					</div>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						className={"text-altblack font-rubik font-medium text-sm pl-[8px]"}
						key={headCell.id}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={getSortDirection(headCell.id)}
					>
						{headCell.canSort ? (
							<TableSortLabel
								active={isActive(headCell.id)}
								direction={sortOrder[headCell.id] ? (sortOrder[headCell.id].order as any) : undefined}
								onClick={createSortHandler(headCell.id)}
								disableRipple={true}
								title={getTooltip(headCell.id)}
								IconComponent={
									(sortOrder[headCell.id] == null
										? () => Sortable({})
										: sortOrder[headCell.id].order === "desc"
											? SortDescending
											: SortAscending) as any
								}
								sx={{
									"& .MuiTableSortLabel-icon": {
										fontSize: "35px",
									},
								}}
							>
								<span className={headCell.label === "Status" ? "ml-[8px]" : ""}>{headCell.label}</span>
							</TableSortLabel>
						) : (
							<div>
								<span className={"ml-[8px]"}>{headCell.label}</span>
							</div>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

// Fields in UpdateMulti have to track exactly the names of the same fields in HelpTicketFull!
interface UpdateMulti {
	priority?: number;
	status?: number;
	assignedServicingTeamID?: number;
}
function getAssigneeIndex(row: HelpTicketRow) {
	let retval = -1;
	let guid = row.assignedDistrictGUID
		? row.assignedDistrictGUID
		: row.assignedPatronGUID
			? row.assignedPatronGUID
			: row.servicingTeamID
				? "" + row.servicingTeamID
				: -1;
	if (guid === -1 || row.helpTicketAssignees === undefined || row.helpTicketAssignees.length === 0) {
		return -1;
	}

	retval = _.findIndex(row.helpTicketAssignees, (item) => guid === item.id);

	return retval;
}
const adapter = (data: HelpTicketFull[], featureFlags: { [key: string]: boolean }) => {
	let rowsReturned: HelpTicketRow[] = [];

	if (data) {
		for (let i = 0; i < data.length; i++) {
			let item = data[i];

			let districtUserName = getLastFirstMiddleNameDestinyUser(_.get(item, "assignedDistrictUser"));
			let retval: HelpTicketRow = {
				helpTicketGUID: item.helpTicketGUID || "",
				item: item.item,
				summary: item.summary,
				// category: item.category.helpTicketIssueTypeID,
				// issueType: item.issueType.helpTicketIssueTypeID,
				priority: _.get(item, "priority.id"),
				status: _.get(item, "status.name"),
				assignedPatronName: getLastFirstMiddleNamePatron(_.get(item, "assignedPatron")),
				assignedDistrictUser: districtUserName,
				assignedPatronGUID: _.get(item, "assignedPatron.userGuid"),
				assignedDistrictGUID: _.get(item, "assignedDistrictUser.userGUID"),
				statusUpdatedDuration: _.get(item, "statusUpdatedDuration"),
				statusCreatedDuration: _.get(item, "statusCreatedDuration"),
				servicingTeamID: _.get(item, "assignedServicingTeam.id"),
				servicingTeamName: _.get(item, "assignedServicingTeam.name"),
				dateCreated: item.dateCreated,
				dateUpdated: item.dateUpdated,
				helpTicketAssignees: item.helpTicketAssignees ? convertAssigneesToMenu(item) : [],
				ticketNumber: _.get(item, "ticketNumber", 0),
			};
			rowsReturned.push(retval);
		}
	}
	return rowsReturned;
};
interface TicketsListOverviewProps {
	isFiltering: boolean;
}
export default function TicketsListOverview(props: TicketsListOverviewProps) {
	const [selected, setSelected] = React.useState([]);
	const [updates, setUpdates]: [UpdateMulti, (value: UpdateMulti) => void] = useState({});
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownIndex, setShowDropdownIndex] = useState(0);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isViewingHelpTicket, setIsViewingHelpTicket] = React.useState(false);
	const [helpTicketBeingViewedGUID, setHelpTicketBeingViewedGUID] = React.useState<string>();
	const [helpTicketsBeingClosed, setHelpTicketsBeingClosed] = React.useState<Array<string>>();
	const [showTicketCloseDialog, setShowTicketCloseDialog] = useState(false);
	const [selectedReason, setSelectedReason] = useState(undefined);
	const [selectedReasonId, setSelectedReasonId] = useState(0);
	const [descriptionError, setDescriptionError] = useState(undefined);
	const [resolutionError, setResolutionError] = useState(undefined);
	const [searchTerm, setSearchTerm] = useState("");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [sort, setSort]: [Array<SortSpec>, (value: Array<SortSpec>) => void] = useState([{ orderBy: "dateUpdated", order: "desc" }] as Array<SortSpec>);
	const [updateHelpTicket, updateResult] = useUpdateHelpTicketMutation();
	const ticketFilter: FilterForm = useAppSelector(selectHelpTicketFilterForm);
	const helpTicketsVO: HelpTicketsVO = useAppSelector(selectHelpTickets);
	const elementRef = useRef(null);
	const [width, setWidth] = useState(0);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const featureFlags = getFeatureFlags();
	// useLayoutEffect(() => {
	// 	if (elementRef.current) {
	// 		setWidth(elementRef.current.offsetWidth);
	// 	}
	// }, []);

	useLayoutEffect(() => {
		const updateTargetWidth = () => {
			if (elementRef.current) {
				setWidth((elementRef.current as HTMLElement).offsetWidth);
			}
		};
		// Initial width setting
		updateTargetWidth();

		// Update width on window resize
		window.addEventListener("resize", updateTargetWidth);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateTargetWidth);
		};
	}, []);

	// Help tickets
	let helpTickets: Array<HelpTicketFull> = helpTicketsVO.helpTickets || [];
	let totalCount: number = helpTicketsVO.totalCount || -1;
	let helpTicketsQuery;
	helpTicketsQuery = useRequestedMyHelpTicketsFullQuery({
		searchTerm,
		pageSize: rowsPerPage,
		pageNumber: page,
		sort: sort,
		filter: ticketFilter,
	});
	if (helpTicketsQuery.isSuccess && !helpTicketsQuery.isFetching) {
		helpTickets = helpTicketsQuery.data.helpTickets;
		totalCount = _.get(helpTicketsQuery, "data.totalCount", -1); // negative 1 for indeterminate mode
	}
	if (helpTicketsQuery.isError) {
		notifyDialog("Error", "Unable to retrieve your tickets", true, () => {
			navigate("/helpdesk/401page");
		});
	}

	let rows = adapter(helpTickets, featureFlags) || [];
	let detailsRef = useRef();

	// Resolutions
	let resolutionsQuery: any = useResolutionsQuery();
	let resolutions: Array<Resolution> = [];
	if (resolutionsQuery.isError) {
		resolutions = [];
	}
	if (resolutionsQuery.isSuccess) {
		// console.log("useResolutionsQuery ");
		resolutions = resolutionsQuery.data;
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (!event.target || !(event.target as HTMLElement).closest(".dropdown-container")) {
				setShowDropdown(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSelectAllClick = (event: any) => {
		if (event.target.checked) {
			const newSelected = _.filter(helpTickets, (helpTicket) => helpTicket?.status?.id !== 2).map((helpTicket) => helpTicket.helpTicketGUID);
			if (selected && selected.length > 0) {
				setSelected(_.uniq((selected as any[]).concat(newSelected)) as SetStateAction<never[]>);
			} else {
				setSelected(newSelected as SetStateAction<never[]>);
			}
			return;
		} else {
			let newSelected = _.cloneDeep(selected);
			for (let i = 0; i < helpTickets.length; i++) {
				let idx = _.findIndex(newSelected, (item) => item === helpTickets[i].helpTicketGUID);
				if (idx >= 0) {
					newSelected.splice(idx, 1);
				}
			}
			setSelected(newSelected);
		}
	};

	const handleClick = (event: any, id: any) => {
		const selectedIndex = selected.indexOf(id as never);
		let newSelected: never[] = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, [id as never]);
		} else {
			selected.splice(selectedIndex, 1);
			newSelected = newSelected.concat(selected);
		}
		setSelected(newSelected as SetStateAction<never[]>);
	};

	const setClosingHelpTicket = (row: HelpTicketRow) => {
		setHelpTicketsBeingClosed([row.helpTicketGUID]);
	};

	const isSelected = (id: any) => selected.indexOf(id as never) >= 0;

	const updateTicketMulti = async (helpTicketUpdateVO: HelpTicketUpdateVO, shouldSkipNotify: boolean = false) => {
		updateTicket("Ticket(s) updated.", helpTicketUpdateVO, shouldSkipNotify);
	};

	const updateTicket = async (preamble: string, helpTicketUpdateVO: HelpTicketUpdateVO, shouldSkipNotify: boolean = false) => {
		if (!updateResult.isLoading) {
			try {
				await updateHelpTicket({ update: helpTicketUpdateVO }).unwrap();
				if (!shouldSkipNotify) {
					let message: string[] = [];
					if (helpTicketUpdateVO.status !== undefined) {
						message.push("Status updated to " + getTicketStatus(helpTicketUpdateVO.status).name);
					}
					if (helpTicketUpdateVO.priority !== undefined) {
						message.push("Priority updated to " + getPriority(helpTicketUpdateVO.priority).name);
					}
					if (helpTicketUpdateVO.assignedDistrictUserGUID !== undefined) {
						message.push("Assigned to district user");
					}
					if (helpTicketUpdateVO.assignedPatronGUID !== undefined) {
						message.push("Assigned to patron user");
					}
					if (helpTicketUpdateVO.assignedDistrictUserGUID === undefined && helpTicketUpdateVO.assignedPatronGUID === undefined) {
						if (helpTicketUpdateVO.assignedServicingTeamID !== undefined && helpTicketUpdateVO.assignedServicingTeamID >= 0) {
							message.push("Assigned to servicing team");
						}
						if (helpTicketUpdateVO.assignedServicingTeamID !== undefined && helpTicketUpdateVO.assignedServicingTeamID < 0) {
							message.push("Ticket has been unassigned");
						}
					}
					if (helpTicketUpdateVO.issueCategory !== undefined) {
						message.push(`Category updated`);
					}
					if (helpTicketUpdateVO.issueType !== undefined) {
						message.push(`Issue type updated`);
					}
					if (helpTicketUpdateVO.note !== undefined) {
						message.push(`Note added`);
					}
					if (message.length > 0) {
						notifyList(preamble, message, false);
					} else {
						notify("Ticket updated", "", false);
					}
				}
			} catch (error) {
				notify("Error", "Failed updating the help ticket", true);
				// console.error("Failed to update the help ticket: " + error);
			}
		}
	};

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event: any, property: string) => {
		let mySort: SortSpec[] = [];
		let order: "asc" | "desc" = "asc";
		if (sort && sort.length > 0) {
			let idx = _.findIndex(sort, (item) => {
				return property === item.orderBy;
			});
			if (idx >= 0) {
				order = sort[idx].order.toLowerCase() === "asc" ? "desc" : "asc";
			}
		}
		mySort.push({ orderBy: property, order });
		if (property !== "dateUpdated") {
			mySort.push({ orderBy: "dateUpdated", order: "desc" }); /// default secondary sort
		}
		setSort(mySort);
	};

	if (totalCount === 0 && !helpTicketsQuery.isLoading) {
		if (props.isFiltering) {
			return <TicketsNoResults />;
		} else {
			return <TicketsColdStart />;
		}
	}
	return (
		<div>
			{(resolutionsQuery.isLoading || helpTicketsQuery.isLoading || helpTicketsQuery.isFetching) && <CommonProgress message="" />}
			<div className={"mb-4 drop-shadow-[0_2px_12px_rgba(138,146,163,0.28)]"} ref={elementRef}>
				<Box sx={{ width: "100%" }}>
					<Paper
						sx={{
							width: "100%",
							borderRadius: "8px",
						}}
						className={"pl-5 pt-5"}
					>
						<TableContainer
							id="ticketsContainer"
							sx={{
								"& .MuiTableContainer-root": { overflow: "visible", overflowX: "unset" },
							}}
							className={"overflow-x-auto"}
						>
							<Table
								sx={{ minWidth: 750, "& .MuiTable-root": { overflow: "visible" } }}
								aria-labelledby="tableTitle"
								size={"medium"}
								className={"overflow-visible"}
							>
								<EnhancedTableHead
									helpTickets={helpTickets}
									selected={selected}
									sort={sort}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={helpTickets.length}
								/>
								<TableBody className=" font-rubik">
									{rows.map((row, index) => {
										// console.log("helpTicket #" + index + ": " + row.helpTicketGUID + " " + row.summary);
										let isItemSelected = isSelected(row.helpTicketGUID);
										// console.log("ItemSelected: " + isItemSelected + " " + row.helpTicketGUID);
										let labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={"row_" + row.helpTicketGUID + index}
												selected={isItemSelected}
												className={" bg-white hover:bg-open-lighter"}
											>
												<TableCell padding="checkbox">
													<Checkbox
														key={"checkbox_" + row.helpTicketGUID}
														color="primary"
														checked={isItemSelected}
														inputProps={{
															"aria-labelledby": labelId,
														}}
														onClick={(event) => handleClick(event, row.helpTicketGUID)}
														disabled={row.status === "Closed"}
														disableRipple={true}
														sx={{
															"& .MuiSvgIcon-root": {
																fontSize: 26.75,
																marginRight: "7px",
															},
														}}
													/>
												</TableCell>
												<TableCell
													onClick={() => {
														let ht = _.find(helpTickets, (htItem) => row.helpTicketGUID === htItem.helpTicketGUID);
														if (ht) {
															setIsViewingHelpTicket(true);
															setHelpTicketBeingViewedGUID(row.helpTicketGUID);
														}
													}}
													sx={{
														cursor: "pointer",
													}}
													padding="none"
													className={"justify-center w-[48px]"}
												>
													<ResourceImage
														id={row.helpTicketGUID}
														key={"resourceImage_" + row.helpTicketGUID}
														resourceGUID={_.get(row, "item.resource.guid")}
													/>
												</TableCell>
												<TableCell
													// component="th"
													key={"summary_" + row.helpTicketGUID}
													id={labelId}
													scope="row"
													padding="none"
													onClick={() => {
														if (helpTickets && helpTickets.length > 0) {
															let ht = _.find(helpTickets, (htItem) => row.helpTicketGUID === htItem.helpTicketGUID);
															if (ht) {
																setIsViewingHelpTicket(true);
																setHelpTicketBeingViewedGUID(row.helpTicketGUID);
															}
														}
													}}
													sx={{
														cursor: "pointer",
														minWidth: "70px",
														maxWidth: { xs: 100, md: 170, lg: 300, xl: 400 },
														// maxWidth: 500,
														paddingLeft: "16px",
													}}
												>
													<div className={"flex flex-col break-words"}>
														<div className={"font-rubik font-medium text-base"}>{row.summary}</div>
														<div className={"font-roboto font-normal text-sm text-altblack"}>
															{row?.item.resource.name || "N/A"}
														</div>
													</div>
												</TableCell>
												<TableCell padding="none" sx={{ width: 70 }} className={"p-0"} key={"priority_" + row.helpTicketGUID}>
													<FormControl
														// className={"w-20 mr-4"}
														size={"small"}
														sx={{
															"& .MuiFormControl-root": {
																backgroundColor: "transparent",
															},
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
															"& .MuiFormLabel-root": {
																color: "black",
															},
														}}
													>
														<Select
															id={"select-priority" + row.helpTicketGUID}
															key={"select-priority" + row.helpTicketGUID}
															label={"Priority"}
															onChange={(event) => {
																// let result = _.cloneDeep(row);
																// result["priority"] = event.target.value; // adapted value
																let update: HelpTicketUpdateVO = {
																	helpTicketGUID: row.helpTicketGUID,
																	priority: parseInt("" + event.target.value, 10),
																};
																let message = `Ticket ${row.ticketNumber == 0 ? "" : "#" + row.ticketNumber} Updated`;
																updateTicket(message, update);
															}}
															autoWidth={true}
															// fullWidth
															value={row.priority || 0}
															className={"text-altblack font-openSans font-semibold text-sm  w-28"}
															disabled={row.status === "Closed" || selected.length > 0}
															IconComponent={() => (
																<img
																	src={"/down-icon.svg"}
																	alt={"Down arrow icon"}
																	className={"pointer-events-none absolute right-[1px]"}
																/>
															)}
														>
															<MenuItem value={0} key={"priority-0"}>
																Low
															</MenuItem>
															<MenuItem value={1} key={"priority-1"}>
																Medium
															</MenuItem>
															<MenuItem value={2} key={"priority-2"}>
																High
															</MenuItem>
														</Select>
													</FormControl>
												</TableCell>
												<TableCell
													padding="none"
													sx={{ width: { xs: 70, sm: 100, md: 125, lg: 200 } }}
													className={"p-0 "}
													key={"assigned_" + row.helpTicketGUID}
												>
													<FormControl
														className={"min-w-max mx-4"}
														size={"small"}
														sx={{
															"& .MuiFormControl-root": {
																backgroundColor: "transparent",
															},
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
															"& .MuiFormLabel-root": {
																color: "black",
															},
														}}
													>
														{row.helpTicketAssignees.length > 0 ? (
															<Select
																id={"select-assigned"}
																label={"Assigned"}
																onChange={(e) => {
																	let value: number = parseInt("" + e.target.value, 10);
																	if (value !== -1) {
																		let assigned: AssigneeMenuItem = row.helpTicketAssignees[value];
																		if (assigned.type === "team") {
																			// serviceTeam selected
																			let update: HelpTicketUpdateVO = {
																				helpTicketGUID: row.helpTicketGUID,
																				assignedServicingTeamID: assigned.serviceTeamID as number,
																				assignedDistrictUserGUID: undefined,
																				assignedPatronGUID: undefined,
																			};
																			let message = `Ticket #${row.ticketNumber} Updated`;
																			updateTicket(message, update);
																		} else {
																			if (assigned.isDistrictUser) {
																				let update: HelpTicketUpdateVO = {
																					helpTicketGUID: row.helpTicketGUID,
																					assignedServicingTeamID: assigned.serviceTeamID,
																					assignedDistrictUserGUID: assigned.id as string,
																					assignedPatronGUID: undefined,
																				};
																				let message = `Ticket #${row.ticketNumber} Updated`;
																				updateTicket(message, update);
																			} else {
																				let update: HelpTicketUpdateVO = {
																					helpTicketGUID: row.helpTicketGUID,
																					assignedServicingTeamID: assigned.serviceTeamID,
																					assignedDistrictUserGUID: undefined,
																					assignedPatronGUID: assigned.id as string,
																				};
																				let message = `Ticket #${row.ticketNumber} Updated`;
																				updateTicket(message, update);
																			}
																		}
																	} else {
																		// unassign
																		let update: HelpTicketUpdateVO = {
																			helpTicketGUID: row.helpTicketGUID,
																			assignedServicingTeamID: -1,
																			assignedDistrictUserGUID: undefined,
																			assignedPatronGUID: undefined,
																		};
																		let message = `Ticket #${row.ticketNumber} Updated`;
																		updateTicket(message, update);
																	}
																}}
																autoWidth={true}
																value={getAssigneeIndex(row)} // adapted
																className={"text-altblack font-openSans font-semibold text-sm"}
																disabled={row.status === "Closed" || selected.length > 0}
																IconComponent={() => (
																	<img
																		src={"/down-icon.svg"}
																		alt={"Down arrow icon"}
																		className={"mr-2 pointer-events-none absolute right-[1px]"}
																	/>
																)}
															>
																<MenuItem value={-1} key={"assigned-Not-assiged"} disabled={false}>
																	<span className={"text-wrap"}> Not Assigned</span>
																</MenuItem>
																{row.helpTicketAssignees &&
																	row.helpTicketAssignees.length > 0 &&
																	row.helpTicketAssignees.map((assignee, idx) => {
																		return (
																			<MenuItem
																				disabled={assignee.serviceTeamID === undefined && assignee.type === "team"}
																				value={idx}
																				key={"assigned-" + assignee.id}
																			>
																				{assignee.type === "team" ? (
																					<span className={"text-wrap font-medium"}>{assignee.name}</span>
																				) : (
																					<span className={"text-wrap"}>{assignee.name}</span>
																				)}
																			</MenuItem>
																		);
																	})}
															</Select>
														) : (
															<div className={"flex flex-col break-words"}>
																<div className={"font-openSans font-semibold text-sm text-altblack"}>
																	{getAssigneeName(row)}
																</div>
															</div>
														)}
													</FormControl>
												</TableCell>
												<TableCell
													className={"text-altblack font-openSans font-semibold text-sm  pl-[8px]"}
													key={"dateCreated_" + row.helpTicketGUID}
													padding="none"
													scope={"row"}
													sx={{ width: 110 }}
												>
													{row.statusCreatedDuration ? row.statusCreatedDuration : getDuration(row.dateCreated)}
												</TableCell>
												<TableCell
													className={"text-altblack font-openSans font-semibold text-sm pl-[8px]"}
													key={"dateUpdated_" + row.helpTicketGUID}
													padding="none"
													scope={"row"}
													sx={{ width: 110 }}
												>
													{row.statusUpdatedDuration ? row.statusUpdatedDuration : getDuration(row.dateUpdated)}
												</TableCell>
												<TableCell sx={{ width: 148 }} key={"status_" + row.helpTicketGUID}>
													<StatusSelect
														onChange={(status: string) => {
															if (status !== row.status) {
																if (["Open", "InProgress", "OnHold"].includes(status)) {
																	let update: HelpTicketUpdateVO = {
																		helpTicketGUID: row.helpTicketGUID,
																		status: getTicketStatus(status).id,
																	};
																	let message = `Ticket #${row.ticketNumber} Updated`;
																	updateTicket(message, update);
																} else if (status === "Closed") {
																	setHelpTicketsBeingClosed([row.helpTicketGUID]);
																	setShowTicketCloseDialog(true);
																}
															}
														}}
														keyIdx={"statusSelect_" + row.helpTicketGUID}
														status={row.status || "Open"}
														disabled={row.status === undefined || row.status === "Closed" || selected.length > 0}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100, 200]}
							component="div"
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
						{selected.length > 0} {<div className={"w-full  h-32 s:h-24 mds:h-14"}></div>}
					</Paper>
				</Box>
			</div>
			{selected.length > 0 && (
				<div className={"min-h-screen max-h-screen min-w-full fixed left-0 top-0  px-4  flex pointer-events-none z-20"}>
					<div className={"pl-[4rem] flex flex-col grow items-center"}>
						<div className={"grow shrink"}></div>
						<div
							className={"bg-[#F2F8FD] h-fit pointer-events-auto flex flow-row gap-1 flex-wrap items-center py-3"}
							style={{ width: `${width}px` }}
						>
							<div className={"flex  mx-6 min-w-[8.4rem]"}>{`${selected.length} ticket` + (selected.length === 1 ? "" : "s") + ` selected`}</div>
							<div className={"flex flex-row "}>
								<FormControl className={"flex w-28 mr-4 bg-[#F2F8FD]"} size={"small"}>
									<InputLabel id="select-priority-label">Priority</InputLabel>
									<Select
										labelId={"select-priority-label"}
										id={"select-priority"}
										label={"Priority"}
										onChange={(e) => {
											let result: UpdateMulti = _.cloneDeep(updates);
											result["priority"] = parseInt("" + e.target.value, 10);
											setUpdates(result);
										}}
										IconComponent={() => (
											<img
												src={"/multi-down-icon.svg"}
												alt={"Down arrow icon"}
												className={"mr-2 pointer-events-none absolute right-[1px]"}
											/>
										)}
										sx={{
											"& .MuiSelect-select": {
												paddingRight: "0px !important",
											},
										}}
									>
										<MenuItem value={0} key={"priority-0"}>
											Low
										</MenuItem>
										<MenuItem value={1} key={"priority-1"}>
											Medium
										</MenuItem>
										<MenuItem value={2} key={"priority-2"}>
											High
										</MenuItem>
									</Select>
								</FormControl>

								<FormControl className={"flex w-28 bg-[#F2F8FD]"} size={"small"}>
									<InputLabel id="select-status-label">Status</InputLabel>
									<Select
										labelId={"select-status-label"}
										id={"select-status"}
										label={"Status"}
										onChange={(e) => {
											let result = {
												...updates,
												status: getTicketStatus(e.target.value as string).id,
											};
											setUpdates(result);
										}}
										className={"break-words"}
										IconComponent={() => (
											<img
												src={"/multi-down-icon.svg"}
												alt={"Down arrow icon"}
												className={"mr-2 pointer-events-none absolute right-[1px]"}
											/>
										)}
										sx={{
											"& .MuiSelect-select": {
												paddingRight: "0px !important",
											},
											width: "155px",
										}}
									>
										<MenuItem value={"Open"} key={"status-0"}>
											<div className="flex flex-row align-items w-[125px] font-semibold text-altblack">
												<img src={getTicketStatusIconBlack("Open")} />
												<span className="pl-2">Open</span>
											</div>
										</MenuItem>
										<MenuItem value={"InProgress"} key={"status-1"}>
											<div className="flex flex-row align-items w-[125px] font-semibold  text-altblack">
												<img src={getTicketStatusIconBlack("InProgress")} />
												<span className="pl-2">In Progress</span>
											</div>
										</MenuItem>
										<MenuItem value={"OnHold"} key={"status-2"}>
											<div className="flex flex-row align-items w-[125px] font-semibold text-altblack">
												<img src={getTicketStatusIconBlack("OnHold")} />
												<span className="pl-2">On Hold</span>
											</div>
										</MenuItem>
										<MenuItem value={"Closed"} key={"status-3"}>
											<div className="flex flex-row align-items w-[125px] font-semibold text-altblack">
												<img src={getTicketStatusIconBlack("Closed")} />
												<span className="pl-2">Closed</span>
											</div>
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className={"flex pl-8 bg-[#F2F8FD] ml-auto"}>
								<div className={"flex mr-1 "}>
									<CustomButton
										onClick={() => {
											setSelected([]);
										}}
										variant={""}
										ariaLabel={"Cancel button"}
									>
										Cancel
									</CustomButton>
								</div>
								<div className={"flex mr-6"}>
									<CustomButton
										onClick={() => {
											let selectedHelpTickets: Array<string> = [];
											selected.map((id) => {
												if (updates.status === undefined || getTicketStatus(updates.status).name !== "Closed") {
													let update: HelpTicketUpdateVO = {
														helpTicketGUID: id,
														...updates,
													};
													updateTicketMulti(update);
												} else if (updates.status && getTicketStatus(updates.status).name === "Closed") {
													// setSkipNotify(true);
													let update: HelpTicketUpdateVO = {
														helpTicketGUID: id,
														...updates,
													};
													// Status is updated in close dialog so don't update here
													update.status = undefined;
													updateTicketMulti(update, true);
													selectedHelpTickets.push(id);
												}
											});
											if (selectedHelpTickets.length > 0) {
												setShowTicketCloseDialog(true);
												setHelpTicketsBeingClosed(selectedHelpTickets);
												// setSkipNotify(false);
											} else {
												setUpdates({});
											}
										}}
										variant={"contained"}
										ariaLabel={"Update tickets button"}
										disabled={updates.priority === undefined && updates.status === undefined}
										widthOverride={"min-w-[10.4rem]"}
									>
										{`Update ${selected.length} Ticket` + (selected.length === 1 ? "" : "s")}
									</CustomButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{isViewingHelpTicket && (
				<ViewTicket
					helpTicketGUID={helpTicketBeingViewedGUID || ""}
					isViewingHelpTicket={isViewingHelpTicket}
					setIsViewingHelpTicket={setIsViewingHelpTicket}
					canEditHelpTicket={true}
					updateTicket={updateTicket}
				/>
			)}
			{showTicketCloseDialog && (
				<Dialog
					open={showTicketCloseDialog}
					sx={{
						"& .MuiPaper-root": {
							borderRadius: "8px",
						},
						"& .MuiBackdrop-root": {
							backgroundColor: "#171646",
							opacity: "70%!important",
						},
					}}
				>
					<div className={"p-5 md:max-w-[36rem]"}>
						<div className={"font-medium font-rubik text-lg text-[#171646]"}>Please provide a reason</div>
						<div className={"flex flex-wrap gap-1 mt-3"}>
							{resolutions.map((resolution) => {
								if (resolution.active) {
									return (
										<button
											value={resolution.helpTicketResolutionID}
											className={
												"rounded-[24px] text-primary text-bold border border-primary p-1 px-2 " +
												(selectedReason === resolution.name ? "bg-primary text-white" : "")
											}
											onClick={() => {
												setSelectedReason(("" + resolution.name) as any);
												setSelectedReasonId(resolution.helpTicketResolutionID as number);
											}}
										>
											{resolution.name}
										</button>
									);
								}
							})}
						</div>
						<div className="block text-error-text text-base mt-1">{resolutionError ? resolutionError : ""}&nbsp;</div>
						<div className={"flex flex-col mt-2"}>
							<TextField
								placeholder={"Explain the resolution."}
								id="resolutionDescription"
								label="Resolution Details"
								multiline
								rows={4}
								inputRef={detailsRef}
								defaultValue={""}
								// onChange={(event) => {
								// 	setResolutionDetails(event.target.value.substring(0, 1000));
								// }}
								className={"border border-[#D3D3D4] rounded-[8px] p-2"}
							/>
							{descriptionError && <div className="block text-error-text text-base">Please enter a description</div>}
							<div className={"flex flex-row mt-8"}>
								<div className={"grow"} />
								<CustomButton
									onClick={() => {
										setShowTicketCloseDialog(false);
										setSelectedReason(undefined);
										setSelectedReasonId(0);
										setResolutionError(undefined);
										setDescriptionError(undefined);
										// setSkipNotify(false);
									}}
									variant={"text"}
									ariaLabel="Cancel button for closing a ticket"
								>
									Cancel
								</CustomButton>
								<CustomButton
									onClick={() => {
										// setTriedClosingTicket(true);
										let ok = true;
										let resolutionDetails = _.get(detailsRef, "current.value", "");
										if (selectedReasonId !== 0) {
											setResolutionError(undefined);
										} else {
											ok = false;
											setResolutionError("Please select a resolution code." as any);
										}
										if (resolutionDetails !== "") {
											setDescriptionError(undefined);
										} else {
											ok = false;
											setDescriptionError("Please enter a description." as any);
										}
										if (helpTicketsBeingClosed !== undefined && helpTicketsBeingClosed.length > 0 && ok) {
											for (let i = 0; i < helpTicketsBeingClosed.length; i++) {
												let update: HelpTicketUpdateVO = {
													helpTicketGUID: helpTicketsBeingClosed[i],
													resolutionID: selectedReasonId,
													status: getTicketStatus("Closed").id,
													resolutionDescription: resolutionDetails,
												};
												let message = "Ticket Updated";
												updateTicket(message, update);
											}
											setShowTicketCloseDialog(false);
											setSelected([]);
											// setTriedClosingTicket(false);
											setSelectedReason(undefined);
											setSelectedReasonId(0);
											setUpdates({});
										}
									}}
									variant={"contained"}
									ariaLabel="Cancel button for closing a ticket"
								>
									Close Ticket{selected.length > 1 ? "s" : ""}
								</CustomButton>
							</div>
						</div>
					</div>
				</Dialog>
			)}
		</div>
	);
}
