import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import TicketsListOverview from "../../components/TicketsListOverview";
import { useAppSelector } from "../../hook";
import { FilterForm } from "../../models/filterForm.model";
import { SortSpec } from "../../models/helpticket.model";
import { selectHelpTicketFilterForm } from "../../slices/FiltersSlice";
import { getFeatureFlags } from "../../utilities/appContext";

export default function AgentPage() {
	const [isFiltering, setIsFiltering] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [sort, setSort]: [Array<SortSpec>, (value: Array<SortSpec>) => void] = useState([{ orderBy: "dateUpdated", order: "desc" }] as Array<SortSpec>);
	// const [updateHelpTicket, { isLoading }] = useUpdateHelpTicketMutation();
	// const [refreshMe, setRefreshMe] = useState(0);
	// const dispatch = useAppDispatch();
	const navigate = useNavigate();
	// const userType = useAppSelector(selectUserType);
	const ticketFilter: FilterForm = useAppSelector(selectHelpTicketFilterForm);
	const featureFlags = getFeatureFlags();
	// const handleRefresh = () => {
	// 	setRefreshMe(refreshMe + 1);
	// }

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event: any, property: string) => {
		let mySort: SortSpec[] = [];
		let order: "asc" | "desc" = "asc";
		if (sort && sort.length > 0) {
			let idx = _.findIndex(sort, (item) => {
				return property === item.orderBy;
			});
			if (idx >= 0) {
				order = sort[idx].order.toLowerCase() === "asc" ? "desc" : "asc";
			}
		}
		mySort.push({ orderBy: property, order });
		if (property !== "dateUpdated") {
			mySort.push({ orderBy: "dateUpdated", order: "desc" }); /// default secondary sort
		}
		setSort(mySort);
	};

	return (
		<div className={"min-w-screen min-h-screen flex flex-row"}>
			<Sidebar
				pageMode={"Home"}
				setPageMode={(value: string) => {
					navigate("/helpdesk/newTicketAgent");
				}}
				disableSettings={true}
			/>
			<div className={"pl-[4rem] w-full"}>
				<div className={"px-4 "}>
					<header>
						<Header
							title={"Tickets"}
							onFilter={(value: boolean) => {
								setIsFiltering(value);
							}}
						/>
					</header>
					<main>
						<TicketsListOverview isFiltering={isFiltering} key="list_agent" />
					</main>
				</div>
			</div>
		</div>
	);
}
