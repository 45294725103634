import { CloseOutlined } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Checkbox, Divider, Drawer, MenuItem, Select } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import _ from "lodash";
import { useEffect, useState } from "react";
import Switch from "react-switch";
import { VAR_CHAR_MAX } from "../../index";
import {
	blankHelpTicketInboundSMTPServerSettingsVO,
	blankHelpTicketOutboundSMTPServerSettingsVO,
	blankHelpTicketSMTPServerSettingsVO,
	HelpTicketEmailNotificationSettingsVO,
	HelpTicketEmailRecepientVO,
	HelpTicketInboundSMTPServerSettingsVO,
	HelpTicketOutboundSMTPServerSettingsVO,
	HelpTicketSMTPServerSettingsVO,
} from "../../models/emailSMTP.model";
import {
	useGetEmailNotificationSettingQuery,
	useGetEmailNotificationSettingsQuery,
	useGetSMTPServerSettingsQuery,
	useTestEmailMutation,
	useTestServerMutation,
	useUpdateEmailNotificationSettingMutation,
	useUpdateSMTPServerSettingsMutation,
} from "../../utilities/apiApi";
import CustomButton from "../CustomButton/CustomButton";
import { notify } from "../Notification/Notification";

let emailNotificationSetting: HelpTicketEmailRecepientVO;

export default function EmailNotificationsConfiguration() {
	const [emailNotificationSettingIndex, setEmailNotificationSettingIndex] = useState<any>(skipToken);
	let emailNotificationSetting = useGetEmailNotificationSettingQuery({ emailNotificationID: emailNotificationSettingIndex });
	const [errors, setErrors] = useState<Array<string>>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingRule, setIsEditingRule] = useState(false);
	const [createUpdateHelpDeskTicketsViaEmail, setCreateUpdateHelpDeskTicketsViaEmail] = useState(false);
	const [connectionType, setConnectionType] = useState(0);
	const [displayName, setDisplayName] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [serverAddress, setServerAddress] = useState("");
	const [port, setPort] = useState("");
	const [userId, setUserId] = useState("");
	const [password, setPassword] = useState("");
	const [skip, setSkip] = useState(false);
	const [subject, setSubject] = useState("");
	const [selectedPatron, setSelectedPatron] = useState("Requester");
	const [body, setBody] = useState(emailNotificationSetting.data?.body || "");
	const [showEmailConfiguration, setShowEmailConfiguration] = useState(false);
	const [updateSMTPServerSettings, { isLoading }] = useUpdateSMTPServerSettingsMutation();
	const [updateNotificationSettingMutation] = useUpdateEmailNotificationSettingMutation();
	const [testEmailMutation] = useTestEmailMutation();
	const [testServerMutation] = useTestServerMutation();

	// SMTP Server Settings
	let getSMTPServerSettings = useGetSMTPServerSettingsQuery(undefined, { skip });
	if (getSMTPServerSettings.isSuccess) {
		setSkip(true);
		setCreateUpdateHelpDeskTicketsViaEmail(getSMTPServerSettings.data.inboundSettings.allowUpsertFromEmail);
		setConnectionType(getSMTPServerSettings.data.inboundSettings.connectionType);
		setDisplayName(getSMTPServerSettings.data.outboundSettings.displayName);
		setEmailAddress(getSMTPServerSettings.data.outboundSettings.replyToEmailAddress);
		setServerAddress(getSMTPServerSettings.data.inboundSettings.smtpServerAddress);
		setPort(getSMTPServerSettings.data.inboundSettings.port);
		setUserId(getSMTPServerSettings.data.inboundSettings.authenticationUserID);
		setPassword(getSMTPServerSettings.data.inboundSettings.authenticationPassword);
		if (getSMTPServerSettings.data.outboundSettings.replyToEmailAddress !== "") {
			setShowEmailConfiguration(true);
		} else {
			setShowEmailConfiguration(false);
		}
	}

	// Email Notification Settings
	let emailNotificationSettings: HelpTicketEmailNotificationSettingsVO = {};
	let getEmailNotificationSettings = useGetEmailNotificationSettingsQuery(undefined);
	if (getEmailNotificationSettings.isSuccess) {
		emailNotificationSettings = getEmailNotificationSettings.data;
	}

	useEffect(() => {
		setSubject(
			emailNotificationSetting.data?.subject ||
				(typeof emailNotificationSettingIndex === "number" && emailRuleDefaults[emailNotificationSettingIndex - 1][1]) ||
				"",
		);
		setBody(
			emailNotificationSetting.data?.body ||
				(typeof emailNotificationSettingIndex === "number" && emailRuleDefaults[emailNotificationSettingIndex - 1][2]) ||
				"",
		);
	}, [emailNotificationSetting, isEditingRule]);

	const updateNotificationSetting = async (update: HelpTicketEmailRecepientVO, emailNotificationID: number) => {
		if (!updateNotificationSettingMutation.isLoading) {
			try {
				await updateNotificationSettingMutation({ update: update, emailNotificationID: emailNotificationID }).unwrap();
				notify("Success", "Email notification setting was successfully updated.", false);
			} catch (error) {
				console.error("Failed to update the form: " + error);
				notify("Error", "Unable to update the email notification setting.", true);
			}
		}
	};

	const fields = [
		"{Ticket Number}",
		"{Created}",
		"{Ticket Status}",
		"{Priority}",
		"{Issue Summary}",
		"{Issue Details}",
		"{Assigned Servicing Team}",
		"{Assigned Individual}",
		"{Requester Name}",
		"{Requester District ID}",
		"{Requester Barcode}",
		"{Resource Displayable Name}",
		"{Item Barcode}",
		"{Item District ID}",
		"{Item Serial Number}",
		"{Item Department}",
		"{Item Home Location}",
		"{Item Custodian}",
		"{Item Site Name}",
		"{Item Site Short Name}",
		"{Resolution Code}",
		"{Ticket Category/Type}",
		"{Last Comment}",
	];

	const emailRuleDefaults = [
		[
			"For Requesters: New Ticket Created",
			"Your new Help Ticket has been added - Ticket {Ticket Number}",
			"Help Ticket {Ticket Number} has been added for {Resource Displayable Name} with barcode {Item Barcode}.",
		],
		[
			"For Requesters: Agent Comments on Ticket",
			"A comment has been added to your Help Ticket {Ticket Number}",
			"{Assigned Individual} has added a comment to Help Ticket {Ticket Number}. {LastComment}",
		],
		[
			"For Requesters: Agent Changes Status on Ticket",
			"Your Help Ticket status has changed - Ticket {Ticket Number}",
			"{Assigned Individual} has changed the status of your Help Ticket {Ticket Number} for {Resource Displayable Name} with barcode {Item Barcode} to {Ticket Status}.",
		],
		[
			"For Requesters: Agent Changes Priority on Ticket",
			"Your Help Ticket priority has changed - Ticket {Ticket Number}",
			"{Assigned Individual} has changed the priority of your Help Ticket {Ticket Number} for {Resource Displayable Name} with barcode {Item Barcode} to {Priority}.",
		],
		[
			"For Agents: New Ticket Created",
			"New Help Ticket Added - Ticket {Ticket Number}",
			"Help Ticket {Ticket Number} has been added for {Resource Displayable Name} with barcode {Item Barcode}.",
		],
		[
			"For Agents: Requester Comments on Ticket",
			"A comment has been added to Help Ticket {Ticket Number}",
			"{Requestor Name} has added a comment to Help Ticket {Ticket Number}. {LastComment}",
		],
		[
			"For Admins: New Ticket Created",
			"New Help Ticket Added - Ticket {Ticket Number}",
			"Help Ticket {Ticket Number} has been added for {Resource Displayable Name} with barcode {Item Barcode}.",
		],
		[
			"For Admins: Requester Comments on Ticket",
			"A user added a comment to Help Ticket {Ticket Number}",
			"{Requestor Name} has added a comment to Help Ticket {Ticket Number}. {LastComment}",
		],
		[
			"For Admins: Agent Comments on Ticket",
			"An agent added a comment to Help Ticket {Ticket Number}",
			"{Assigned Individual} has added a comment to Help Ticket {Ticket Number}. {LastComment}",
		],
		[
			"For Admins: Agent Changes Status on Ticket",
			"An agent changed the status of Help Ticket {Ticket Number}",
			"{Assigned Individual} has changed the status of Help Ticket {Ticket Number} for {Resource Displayable Name} with barcode {Item Barcode} to {Ticket Status}.",
		],
		[
			"For Admins: Agent Changes Priority on Ticket",
			"An agent changed the priority of Help Ticket {Ticket Number}",
			"{Assigned Individual} has changed the priority of Help Ticket {Ticket Number} for {Resource Displayable Name} with barcode {Item Barcode} to {Priority}.",
		],
	];

	function getConnectionType(connectionType: number) {
		switch (connectionType) {
			case 0:
				return "IMAP";
			case 1:
				return "POP3";
			default:
				return "Unknown";
		}
	}

	function isValidEmailAddress(input: string) {
		const emailAddressRegExp = new RegExp(
			"^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" + "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" + "[a-zA-Z0-9])?)*$",
		);
		return emailAddressRegExp.test(input);
	}

	function validate() {
		let errors = [];
		if (!isValidEmailAddress(emailAddress) && emailAddress !== "") {
			errors.push("Invalid email address");
		}
		if (displayName === "" && emailAddress !== "") {
			errors.push("Display name");
		}
		if (emailAddress === "" && displayName !== "") {
			errors.push("Email address");
		}
		if (serverAddress === "" && createUpdateHelpDeskTicketsViaEmail) {
			errors.push("Server address");
		}
		if (port === "" && createUpdateHelpDeskTicketsViaEmail) {
			errors.push("Port");
		}
		if (userId === "" && createUpdateHelpDeskTicketsViaEmail) {
			errors.push("User ID");
		}
		if (password === "" && createUpdateHelpDeskTicketsViaEmail) {
			errors.push("Password");
		}
		setErrors(errors);
		return errors;
	}

	function validateRule() {
		let errors = [];
		if (subject === "") {
			errors.push("Subject");
		}
		if (body === "") {
			errors.push("Body");
		}
		setErrors(errors);
		return errors;
	}

	async function testEmail() {
		let validated = validate();
		if (!validated.includes("Invalid email address") && !validated.includes("Display name") && !validated.includes("Email address")) {
			try {
				let settings: HelpTicketOutboundSMTPServerSettingsVO = blankHelpTicketOutboundSMTPServerSettingsVO;
				settings.displayName = displayName;
				settings.replyToEmailAddress = emailAddress;

				await testEmailMutation({ helpTicketOutboundSettings: settings }).unwrap();
				notify("Success", "Test email sent. Please verify it was received.", false);
				setSkip(false);
			} catch (error) {
				console.error("Failed to send test email." + error);
				notify("Error", "Unable to send test email. Please verify the email and SMTP server are set up correctly.", true);
			}
		}
	}

	async function testServer() {
		let validated = validate();
		if (!validated.includes("Server address") && !validated.includes("Port") && !validated.includes("User ID") && !validated.includes("Password")) {
			try {
				let settings: HelpTicketInboundSMTPServerSettingsVO = blankHelpTicketInboundSMTPServerSettingsVO;
				settings.smtpServerAddress = serverAddress;
				settings.port = parseInt(port);
				settings.connectionType = connectionType;
				settings.authenticationUserID = userId;
				settings.authenticationPassword = password;

				await testServerMutation({ helpTicketInboundSettings: settings }).unwrap();
				notify("Success", "Test successful.", false);
				setSkip(false);
			} catch (error) {
				console.error("Unable to connect to SMTP (Mail) server." + error);
				notify("Error", "Unable to connect to SMTP (Mail) server.", true);
			}
		}
	}

	async function save() {
		let validated = validate();
		if (errors.length === 0 && validated.length === 0) {
			let serverSettings: HelpTicketSMTPServerSettingsVO = blankHelpTicketSMTPServerSettingsVO;
			let inboundSettings: HelpTicketInboundSMTPServerSettingsVO = blankHelpTicketInboundSMTPServerSettingsVO;
			let outboundSettings: HelpTicketOutboundSMTPServerSettingsVO = blankHelpTicketOutboundSMTPServerSettingsVO;

			inboundSettings.smtpServerAddress = serverAddress.trim();
			inboundSettings.port = parseInt(port);
			inboundSettings.connectionType = connectionType;
			inboundSettings.authenticationUserID = userId.trim();
			inboundSettings.authenticationPassword = password.trim();
			inboundSettings.allowUpsertFromEmail = createUpdateHelpDeskTicketsViaEmail;

			outboundSettings.displayName = displayName.trim();
			outboundSettings.replyToEmailAddress = emailAddress.trim();

			serverSettings.inboundSettings = inboundSettings;
			serverSettings.outboundSettings = outboundSettings;

			if (!isLoading) {
				try {
					await updateSMTPServerSettings({ update: serverSettings }).unwrap();
					notify("Success", "SMTP settings saved successfully.", false);
					setSkip(false);
				} catch (error) {
					console.error("Failed to update the form: " + error);
					notify("Error", "Unable to save SMTP settings.", true);
				}
				setIsEditing(false);
			}
		}
	}

	console.log(subject);

	return (
		<div className={"drop-shadow-[0_2px_12px_rgba(138,146,163,0.28)]"}>
			<div className={"text-lg mb-2"}>Set email notifications</div>
			<div
				className={"flex flex-col p-4 w-full h-36 bg-white rounded-tl-[8px] rounded-tr-[8px]" + (!showEmailConfiguration ? " rounded-[8px]" : "")}
				role={"button"}
				onClick={() => {
					setIsEditing(true);
				}}
			>
				<div className={"text-lg text-altblack mb-1"}>SMTP Server Setups</div>
				<div className={"text-sm font-roboto grow"}>Configure the outbound and inbound email settings</div>
				<div className={"flex flex-row"}>
					<CustomButton
						onClick={() => {
							setIsEditing(true);
						}}
						variant={"contained"}
						ariaLabel={""}
						children={<div>Edit</div>}
					/>
					<div className={"grow"} />
				</div>
			</div>
			{showEmailConfiguration && (
				<div>
					<div className={"px-4 w-auto bg-white"}>
						<Divider className={"w-auto"} />
					</div>
					<div className={"flex flex-col p-4 bg-white rounded-bl-[8px] rounded-br-[8px]"}>
						<div>
							<div className={"text-lg text-altblack mb-1"}>Email Configuration</div>
							<div className={"text-sm font-roboto grow mb-4"}>Configure the outbound emails</div>
							<div>
								<button
									className={
										"mr-4" + (selectedPatron === "Requester" ? " text-primary underline underline-offset-[8px] underline-primary" : "")
									}
									onClick={() => {
										setSelectedPatron("Requester");
									}}
								>
									Requester
								</button>
								<button
									className={"mr-4" + (selectedPatron === "Agent" ? " text-primary underline underline-offset-[8px] underline-primary" : "")}
									onClick={() => {
										setSelectedPatron("Agent");
									}}
								>
									Agent
								</button>
								<button
									className={"" + (selectedPatron === "Admin" ? " text-primary underline underline-offset-[8px] underline-primary" : "")}
									onClick={() => {
										setSelectedPatron("Admin");
									}}
								>
									Admin
								</button>
							</div>
							<Divider className={"w-auto mt-[1px]"} />
						</div>
						{selectedPatron === "Requester" && (
							<div className={"flex flex-col mt-6"}>
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.requesters?.newTicketCreated?.active as boolean}
										disabled={emailNotificationSettings.requesters?.newTicketCreated?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.requesters?.newTicketCreated);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 1);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>New ticket created</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(1);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.requesters?.agentComments?.active as boolean}
										disabled={emailNotificationSettings.requesters?.agentComments?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.requesters?.agentComments);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 2);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent comments on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(2);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.requesters?.agentChangesStatus?.active as boolean}
										disabled={emailNotificationSettings.requesters?.agentChangesStatus?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.requesters?.agentChangesStatus);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 3);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent changes status on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(3);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.requesters?.agentChangesPriority?.active as boolean}
										disabled={emailNotificationSettings.requesters?.agentChangesPriority?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.requesters?.agentChangesPriority);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 4);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent changes priority on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(4);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
							</div>
						)}
						{selectedPatron === "Agent" && (
							<div className={"flex flex-col mt-6"}>
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.agents?.newTicketCreated?.active as boolean}
										disabled={emailNotificationSettings.agents?.newTicketCreated?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.agents?.newTicketCreated);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 5);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>New ticket created</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(5);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.agents?.requesterComments?.active as boolean}
										disabled={emailNotificationSettings.agents?.requesterComments?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.agents?.requesterComments);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 6);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Requester comments on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(6);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
							</div>
						)}
						{selectedPatron === "Admin" && (
							<div className={"flex flex-col mt-6"}>
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.admins?.newTicketCreated?.active as boolean}
										disabled={emailNotificationSettings.admins?.newTicketCreated?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.admins?.newTicketCreated);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 7);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>New ticket created</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(7);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.admins?.requesterComments?.active as boolean}
										disabled={emailNotificationSettings.admins?.requesterComments?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.admins?.requesterComments);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 8);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Requester comments on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(8);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.admins?.agentComments?.active as boolean}
										disabled={emailNotificationSettings.admins?.agentComments?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.admins?.agentComments);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 9);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent comments on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(9);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.admins?.agentChangesStatus?.active as boolean}
										disabled={emailNotificationSettings.admins?.agentChangesStatus?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.admins?.agentChangesStatus);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 10);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent changes status on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(10);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
								<div className={"flex flex-row p-3 my-1"}>
									<Switch
										checked={emailNotificationSettings.admins?.agentChangesPriority?.active as boolean}
										disabled={emailNotificationSettings.admins?.agentChangesPriority?.subject === undefined}
										onChange={(event: any) => {
											let result = _.cloneDeep(emailNotificationSettings.admins?.agentChangesPriority);
											if (result) {
												result.active = event;
												updateNotificationSetting(result, 11);
											}
										}}
										uncheckedIcon={false}
										checkedIcon={false}
										onColor={"#01338D"}
										width={46}
										height={24}
									></Switch>
									<div className={"ml-4"}>Agent changes priority on ticket</div>
									<div className={"grow"} />
									<img
										src={"/pencil.svg"}
										alt={"Pencil icon"}
										role={"button"}
										onClick={() => {
											setEmailNotificationSettingIndex(11);
											setIsEditingRule(true);
										}}
									/>
								</div>
								<Divider />
							</div>
						)}
					</div>
				</div>
			)}
			<Drawer
				open={isEditing}
				anchor={"right"}
				onClose={() => {
					setIsEditing(false);
				}}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
						opacity: "70%!important",
					},
				}}
			>
				<div className={"w-[100vw] lg:w-[50vw] p-4 h-full overflow-y-auto"}>
					<div className={"flex flex-col h-auto"}>
						<div className={"w-full flex flex-row items-start mb-2"}>
							<div className={"font-normal text-2xl text-altblack"}>SMTP Server Setup</div>
							<div className={"grow"} />
							<button
								onClick={() => {
									setIsEditing(false);
								}}
							>
								<CloseOutlined />
							</button>
						</div>
						<div className={"flex flex-col h-full"}>
							<div className={"text-lg text-altblack font-normal mb-2"}>Outbound Settings</div>
							<div className={"font-roboto text-xs mb-2"}>
								Sending of outbound emails is handled by the SMTP server configured in Back Office &gt; Setup &gt; District Options &gt; SMTP
								Settings
							</div>
							<div className={"text-sm"}>Display name</div>
							<input
								className={
									"p-2 rounded-[8px] border border-[#B5B3B3] w-1/2" +
									(errors.includes("Display name", 0) ? " border-error-border bg-error-bg" : "")
								}
								placeholder={"Type display name"}
								value={displayName}
								onChange={(event: any) => {
									setDisplayName(event.target.value);
									if (errors.includes("Display name", 0)) {
										setErrors(errors.filter((error) => error !== "Display name"));
									}
								}}
							/>
							{errors.includes("Display name", 0) && (
								<div className={"text-error-text text-sm w-1/2"}>
									If sending outbound emails, both fields must be populated. Please supply a Display name.
								</div>
							)}
							<div className={"text-sm mt-2"}>Reply-to email address</div>
							<div className={"flex flex-row"}>
								<input
									className={
										"p-2 rounded-[8px] border border-[#B5B3B3] w-1/2" +
										(errors.includes("Email address", 0) || errors.includes("Invalid email address", 0)
											? " border-error-border bg-error-bg"
											: "")
									}
									placeholder={"Enter a valid email address"}
									value={emailAddress}
									onChange={(event) => {
										setEmailAddress(event.target.value);
										if (errors.includes("Email address", 0)) {
											setErrors(errors.filter((error) => error !== "Email address"));
										}
										if (errors.includes("Invalid email address", 0)) {
											setErrors(errors.filter((error) => error !== "Invalid email address"));
										}
									}}
								/>
								<div className={"grow"} />
								<CustomButton
									onClick={() => {
										testEmail();
									}}
									variant={"contained"}
									ariaLabel={""}
									children={<div>Test email</div>}
									bgColor={"white"}
								/>
								<div className={"grow"} />
							</div>
							{errors.includes("Email address", 0) && (
								<div className={"text-error-text text-sm w-1/2"}>
									If sending outbound emails, both fields must be populated. Please supply a Reply-to email.
								</div>
							)}
							{errors.includes("Invalid email address", 0) && (
								<div className={"text-error-text text-sm w-1/2"}>Please enter a valid email address.</div>
							)}
							<div className={"text-lg text-altblack font-normal mt-8 mb-2"}>Inbound Settings</div>
							<div className={"flex flex-row mb-2"}>
								<Checkbox
									checked={createUpdateHelpDeskTicketsViaEmail}
									onChange={(event) => {
										setCreateUpdateHelpDeskTicketsViaEmail(event.target.checked);
									}}
									className={"w-4 h-4"}
									sx={{
										"&.Mui-checked": {
											color: "#000000",
										},
									}}
									size={"small"}
									disableRipple={true}
								></Checkbox>
								<div className={"ml-2 text-sm font-normal font-roboto text-altblack"}>Create/Update Help Desk tickets via email</div>
							</div>
							<div className={"flex flex-row w-full"}>
								<div className={"w-1/2"}>
									<div className={"text-sm"}>
										{createUpdateHelpDeskTicketsViaEmail && <span className={"text-error-text"}>* </span>}SMTP (Mail) Server Address
									</div>
									<input
										className={
											"p-2 rounded-[8px] border border-[#B5B3B3] w-full" +
											(errors.includes("Server address", 0) ? " border-error-border bg-error-bg" : "")
										}
										placeholder={"Enter server address"}
										value={serverAddress}
										onChange={(event) => {
											setServerAddress(event.target.value);
											if (errors.includes("Server address", 0)) {
												setErrors(errors.filter((error) => error !== "Server address"));
											}
										}}
									/>
									{errors.includes("Server address", 0) && (
										<div className={"text-error-text text-sm"}>Server address is required. Please supply a value.</div>
									)}
									<div className={"flex flex-row mt-2"}>
										<div className={"w-1/4"}>
											<div className={"text-sm"}>
												{createUpdateHelpDeskTicketsViaEmail && <span className={"text-error-text"}>* </span>}Port
											</div>
											<input
												className={
													"p-2 rounded-[8px] border border-[#B5B3B3] w-full [&::-webkit-inner-spin-button]:appearance-none" +
													(errors.includes("Port", 0) ? " border-error-border bg-error-bg" : "")
												}
												type="number"
												placeholder={"25"}
												value={port}
												onChange={(event) => {
													if (event.target.value === "" || parseInt(event.target.value) >= 0) {
														setPort(event.target.value);
														if (errors.includes("Port", 0)) {
															setErrors(errors.filter((error) => error !== "Port"));
														}
													}
												}}
												onKeyDown={(event) => {
													if (event.key === "e" || event.key === "E" || event.key === ".") {
														event.preventDefault();
													}
												}}
											/>
										</div>
										<div className={"w-3/4 pl-2"}>
											<div className={"text-sm"}>
												{createUpdateHelpDeskTicketsViaEmail && <span className={"text-error-text"}>* </span>}Connection Type
											</div>
											<Select
												labelId="select-label"
												id="select"
												value={getConnectionType(connectionType)}
												className={"w-full text-base"}
												onChange={(event) => {
													// @ts-ignore
													setConnectionType(event.target.value);
												}}
												sx={{
													backgroundColor: errors.includes("Connection type", 0) ? "#FFF7F7 !important" : "white",
													border: errors.includes("Connection type", 0) ? "1px solid #B43523 !important" : "",
													height: "42px",
												}}
												displayEmpty={true}
												renderValue={() => {
													return getConnectionType(connectionType);
												}}
												IconComponent={() => (
													<img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none"} />
												)}
											>
												<MenuItem value={0}>IMAP</MenuItem>
												<MenuItem value={1}>POP3</MenuItem>
											</Select>
										</div>
									</div>
									{errors.includes("Port", 0) && <div className={"text-error-text text-sm"}>Port is required. Please supply a value.</div>}
									<div className={"text-sm mt-2"}>
										{createUpdateHelpDeskTicketsViaEmail && <span className={"text-error-text"}>* </span>}Authentication User ID
									</div>
									<input
										className={
											"p-2 rounded-[8px] border border-[#B5B3B3] w-full" +
											(errors.includes("User ID", 0) ? " border-error-border bg-error-bg" : "")
										}
										placeholder={"Type User ID"}
										value={userId}
										onChange={(event) => {
											setUserId(event.target.value);
											if (errors.includes("User ID", 0)) {
												setErrors(errors.filter((error) => error !== "User ID"));
											}
										}}
									/>
									{errors.includes("User ID", 0) && (
										<div className={"text-error-text text-sm"}>User ID is required. Please supply a value.</div>
									)}
									<div className={"text-sm mt-2"}>
										{createUpdateHelpDeskTicketsViaEmail && <span className={"text-error-text"}>* </span>}Authentication Password
									</div>
									<input
										className={
											"p-2 rounded-[8px] border border-[#B5B3B3] w-full" +
											(errors.includes("Password", 0) ? " border-error-border bg-error-bg" : "")
										}
										placeholder={"Type authentication password"}
										value={password}
										onChange={(event) => {
											setPassword(event.target.value);
											if (errors.includes("Password", 0)) {
												setErrors(errors.filter((error) => error !== "Password"));
											}
										}}
									/>
									{errors.includes("Password", 0) && (
										<div className={"text-error-text text-sm"}>Password is required. Please supply a value.</div>
									)}
								</div>
								<div className={"w-1/2 flex items-center justify-center"}>
									<CustomButton
										onClick={() => {
											testServer();
										}}
										variant={"contained"}
										ariaLabel={""}
										children={<div>Test server</div>}
										bgColor={"white"}
									/>
								</div>
							</div>
							<div className={"grow"} />
							<div className={"w-full flex flex-row"}>
								<div className={"grow"} />
								<CustomButton
									onClick={() => {
										setIsEditing(false);
									}}
									variant={""}
									ariaLabel={"Cancel button"}
									children={<div>Cancel</div>}
								/>
								<div className={"w-2"} />
								<CustomButton
									onClick={() => {
										save();
									}}
									variant={"contained"}
									ariaLabel={"Save button"}
									children={<div>Save</div>}
								/>
							</div>
						</div>
					</div>
				</div>
			</Drawer>
			<Drawer
				open={isEditingRule}
				anchor={"right"}
				onClose={() => {
					setIsEditingRule(false);
				}}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
						opacity: "70%!important",
					},
				}}
			>
				<div className={"w-[100vw] lg:w-[60vw] p-4 h-full overflow-y-auto"}>
					<div className={"flex flex-col h-[92%] overflow-y-auto"}>
						<div className={"w-full flex flex-row items-start mb-2"}>
							<div className={"font-normal text-2xl text-altblack"}>Edit email message</div>
							<div className={"grow"} />
							<button
								onClick={() => {
									setIsEditingRule(false);
								}}
							>
								<CloseOutlined />
							</button>
						</div>
						<div className={"text-altblack text-lg"}>
							{typeof emailNotificationSettingIndex === "number" && emailRuleDefaults[emailNotificationSettingIndex - 1][0]}
						</div>
						<div className={"flex flex-col sm:flex-row mt-4"}>
							<div className={"sm:w-2/3 pr-8"}>
								<div className={"text-sm text-baseBlack"}>Subject</div>
								<input
									className={
										"p-2 mt-1 rounded-[8px] border border-dashed border-[#01338D] w-full placeholder:text-roboto placeholder:text-md placeholder:text-gray-400" +
										(errors.includes("Subject", 0) ? " border-error-border bg-error-bg" : "")
									}
									placeholder={"Your Help Desk Request Update"}
									onDragOver={(e) => e.preventDefault()}
									onDrop={(e) => {
										e.preventDefault();
										const field = e.dataTransfer.getData("text/plain");
										const textarea = e.target as HTMLTextAreaElement;
										const start = textarea.selectionStart;
										const end = textarea.selectionEnd;
										const text = textarea.value;
										textarea.value = text.substring(0, start) + field + text.substring(end);
										textarea.setSelectionRange(start + field.length, start + field.length);
										setSubject(textarea.value);
									}}
									value={subject}
									onChange={(event) => {
										setSubject(event.target.value);
										if (errors.includes("Subject", 0)) {
											setErrors(errors.filter((error) => error !== "Subject"));
										}
									}}
									maxLength={200}
								/>
								{errors.includes("Subject", 0) && <div className={"text-error-text text-sm"}>Please enter a subject value for the email.</div>}
								<div className={"text-sm mt-4 text-baseBlack"}>Body</div>
								<textarea
									className={
										"p-2 mt-1 rounded-[8px] border border-dashed border-[#01338D] w-full placeholder:text-roboto placeholder:text-md placeholder:text-gray-400" +
										(errors.includes("Body", 0) ? " border-error-border bg-error-bg" : "")
									}
									placeholder={"Your Help Desk Request Update"}
									rows={11}
									onDragOver={(e) => e.preventDefault()}
									onDrop={(e) => {
										e.preventDefault();
										const field = e.dataTransfer.getData("text/plain");
										const textarea = e.target as HTMLTextAreaElement;
										const start = textarea.selectionStart;
										const end = textarea.selectionEnd;
										const text = textarea.value;
										textarea.value = text.substring(0, start) + field + text.substring(end);
										textarea.setSelectionRange(start + field.length, start + field.length);
										setBody(textarea.value);
									}}
									value={body}
									onChange={(event) => {
										setBody(event.target.value);
										if (errors.includes("Body", 0)) {
											setErrors(errors.filter((error) => error !== "Body"));
										}
									}}
									maxLength={VAR_CHAR_MAX}
								/>
								{errors.includes("Body", 0) && <div className={"text-error-text text-sm"}>Please configure the body of the email.</div>}
								<div className={"text-xs text-altblack"}>Drag and drop fields to assist with messaging</div>
							</div>
							<div className={"sm:w-1/3"}>
								<div className={"text-sm text-baseBlack"}>Fields</div>
								{fields.map((field, index) => {
									return (
										<div
											key={index}
											draggable
											onDragStart={(e) => e.dataTransfer.setData("text/plain", field)}
											className={"flex flex-row items-center border border-[#D3D3D4] rounded-[8px] p-2 mb-2"}
										>
											<div className={"ml-2 text-sm grow break-all"}>{field.replace(/{/g, "(").replace(/}/g, ")")}</div>
											<DragIndicatorIcon className={"text-[#01338D]"} />
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className={"h-[2%] w-full"}></div>
					<div className={"h-[6%] w-[96%] absolute"}>
						<div className={"flex flex-row"}>
							<div className={"grow"} />
							<CustomButton
								onClick={() => {
									setIsEditingRule(false);
								}}
								variant={""}
								ariaLabel={"Cancel button"}
								children={<div>Cancel</div>}
							/>
							<div className={"w-2"} />
							<CustomButton
								onClick={() => {
									if (validateRule().length === 0) {
										let result = _.cloneDeep(emailNotificationSetting);
										result.subject = subject;
										result.body = body;
										updateNotificationSetting(result, emailNotificationSettingIndex);
										setIsEditingRule(false);
									}
								}}
								variant={"contained"}
								ariaLabel={"Save button"}
								children={<div>Save</div>}
							/>
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
}
