import { useState } from "react";
import Header from "../../components/Header/Header";
import TicketsColdStart from "../../components/TicketsColdStart";
import TicketsNoResults from "../../components/TicketsNoResults";
import TicketsOverview from "../../components/TicketsOverview";
import { useRequestedMyHelpTicketsFullQuery } from "../../utilities/apiApi";
// import HelpTicketFull from "../../models/helpticket.model";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import CommonProgress from "../../components/CommonProgress/CommonProgress";
import { notifyDialog } from "../../components/Notification/Notification";
import { useAppDispatch, useAppSelector } from "../../hook";
import { FilterForm, SortOption } from "../../models/filterForm.model";
import HelpTicketFull, { HelpTicketsVO } from "../../models/helpticket.model";
import { selectHelpTicketFilterForm } from "../../slices/FiltersSlice";

export default function SubmitterPage() {
	let demo = false;
	const [isFiltering, setIsFiltering] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const ticketFilter: FilterForm = useAppSelector(selectHelpTicketFilterForm);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let helpTickets: Array<HelpTicketFull> = [];
	let sortOptions: SortOption[] = [
		{ orderBy: "status", order: "ASC" },
		{ orderBy: "dateUpdated", order: "DESC" },
	];
	let totalCount: number = 0;
	let helpTicketsResult = useRequestedMyHelpTicketsFullQuery(
		{ searchTerm, pageSize: 50, page: 0, sort: sortOptions, filter: ticketFilter },
		{ refetchOnMountOrArgChange: true },
	);

	if (helpTicketsResult.isSuccess) {
		let result: HelpTicketsVO = helpTicketsResult.data;
		totalCount = _.get(result, "totalCount", -1);
		if (totalCount < 0) {
			totalCount = _.get(result, "helpTickets", []).length;
		}
		helpTickets = _.get(helpTicketsResult, "data.helpTickets", []);
	}

	if (helpTicketsResult.isError) {
		notifyDialog("Error", "Unable to retrieve your tickets", true, () => {
			navigate("/helpdesk/401page");
		});
	}
	if (helpTicketsResult.isLoading || helpTicketsResult.isFetching) {
		return <CommonProgress />;
	}

	return (
		<div className={"min-w-screen min-h-screen"}>
			<div className={"px-[48px]"}>
				<header className="ml-4">
					<Header
						title={"Help Desk"}
						onFilter={(value: boolean) => {
							setIsFiltering(value);
						}}
					/>
				</header>
				<main>
					<>
						{helpTicketsResult.isLoading || helpTicketsResult.isFetching ? (
							<CommonProgress message={"Checking for Tickets..."} />
						) : totalCount > 0 ? (
							<TicketsOverview helpTickets={helpTickets} />
						) : isFiltering ? (
							<TicketsNoResults />
						) : (
							<TicketsColdStart />
						)}
					</>
				</main>
			</div>
		</div>
	);
}
